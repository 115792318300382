#banner{
    position: relative;
    background-color: #fff;
}
.banner-parallax{
    position: relative;
    min-height: 800px;
    background-color: #fff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}
.banner-parallax > .bg-element{
    background-image: url(../../assets/images/banner.jpg);
    background-position: 50% 96px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat top center / cover;
    background-size: cover;
    transition: opacity 0.5s;
}
.banner-parallax > .bg-element + img {
    display: none;
}
.overlay-colored{
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.color-bg-gradient, .icon.spaced.gradient-bg {
    background-color: #44bfff;
    background-image: -webkit-linear-gradient(45deg, #44bfff 0%, #4777f4 100%);
    background-image: -moz-linear-gradient(45deg, #44bfff 0%, #4777f4 100%);
    background-image: -o-linear-gradient(45deg, #44bfff 0%, #4777f4 100%);
    background-image: linear-gradient(45deg, #44bfff 0%, #4777f4 100%);
}
.section-separator.bottom {
    bottom: 0;
}
.section-separator {
    position: absolute;
    overflow: hidden;
    z-index: 1;
    width: 100%;
}