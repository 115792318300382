.footer {
  background: #292d32;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 150px;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
  text-align: center;
  padding-top: 100px;
}
